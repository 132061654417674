<template>
  <BtnTemplate
    class="btn tw-transition-all tw-duration-200"
    :class="[btnClass, { small }]"
    :disabled="disabled"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    @click="$emit('click', $event)"
  >
    <Transition name="fade" mode="out-in">
      <div
        :key="state"
        class="btnText tw-flex tw-items-center tw-justify-start tw-gap-2"
      >
        <i
          v-if="currentStateHasIcon"
          class="tw-block"
          role="img"
          :class="{
            [initialIcon]: !isFinalState && !isLoading,
            [loadingIcon]: isLoading && !isFinalState,
            [finalIcon]: isFinalState,
          }"
        />
        {{ btnText }}
      </div>
    </Transition>
  </BtnTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import BtnTemplate from '~/components/ui/Buttons/template.vue'

enum State {
  initial,
  loading,
  final,
}

export default defineComponent({
  components: {
    BtnTemplate,
  },
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFinalState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingClass: {
      type: String,
      default: 'main cta',
    },
    loadingIcon: {
      type: String,
      default: 'fa fa-spin fa-spinner',
    },
    loadingText: {
      type: String,
      required: false,
      default: '',
    },
    initialClass: {
      type: String,
      default: 'main cta',
    },
    initialIcon: {
      type: String,
      default: '',
    },
    initialText: {
      type: String,
      required: true,
    },
    finalClass: {
      type: String,
      default: 'main cta',
    },
    finalIcon: {
      type: String,
      default: '',
    },
    finalText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: ['mouseover', 'mouseleave', 'click'],
  computed: {
    state(): State {
      return this.isFinalState
        ? State.final
        : this.isLoading
          ? State.loading
          : State.initial
    },
    currentStateHasIcon(): boolean {
      switch (this.state) {
        case State.final:
          return !!this.finalIcon
        case State.loading:
          return !!this.loadingIcon
        case State.initial:
        default:
          return !!this.initialIcon
      }
    },
    btnClass(): string {
      switch (this.state) {
        case State.final:
          return this.finalClass
        case State.loading:
          return this.loadingClass
        case State.initial:
        default:
          return this.initialClass
      }
    },
    btnText(): string {
      switch (this.state) {
        case State.final:
          return this.finalText
        case State.loading:
          return this.loadingText
        case State.initial:
        default:
          return this.initialText
      }
    },
  },
})
</script>
